import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import "../assets/css/main.css";
import Markdown from "react-markdown";
import PythonLogo from "../assets/images/backend/python.svg";
import FlaskLogo from "../assets/images/backend/flask.svg";
import DjangoLogo from "../assets/images/backend/django.svg";
import NodejsLogo from "../assets/images/backend/nodejs.svg";
import ExpressLogo from "../assets/images/backend/express.svg";
import RubyLogo from "../assets/images/backend/ruby.svg";
import Railslogo from "../assets/images/backend/rails.svg";
import PhpLogo from "../assets/images/backend/php.svg";
import LaravelLogo from "../assets/images/backend/laravel.svg";
import LumenLogo from "../assets/images/backend/lumen.svg";
import RedisLogo from "../assets/images/backend/redis.svg";
import HtmlLogo from "../assets/images/backend/html5.svg";
import NginxLogo from "../assets/images/backend/nginx.svg";
import VueLogo from "../assets/images/backend/vue-js.svg";
import ReactLogo from "../assets/images/backend/react.svg";
import AngularLogo from "../assets/images/backend/angular.svg";
import CssLogo from "../assets/images/backend/css.svg";
import JsLogo from "../assets/images/backend/javascript.svg";
import JqueryLogo from "../assets/images/backend/jquery.svg";
import VuetifyLogo from "../assets/images/backend/vuetify.svg";
import TailwindLogo from "../assets/images/backend/tailwindcss.svg";
import ReduxLogo from "../assets/images/backend/redux.svg";
import BootstrapLogo from "../assets/images/backend/bootstrap.svg";
import GmapsLogo from "../assets/images/backend/google-maps-logo.svg";
import SocketLogo from "../assets/images/backend/socket-io.svg";
import AwsLogo from "../assets/images/cloud/aws.svg";
import DigitalLogo from "../assets/images/cloud/digitalocean.svg";
import HerokuLogo from "../assets/images/cloud/heroku.svg";
import AzureLogo from "../assets/images/cloud/azure.svg";
import FirebaseLogo from "../assets/images/cloud/firebase.svg";
import MysqlLogo from "../assets/images/backend/mysql.svg";
import PostgresLogo from "../assets/images/backend/postgresql.svg";
import OracleLogo from "../assets/images/backend/oracle.svg";
import MongodbLogo from "../assets/images/backend/mongodb.svg";
import CouchdbLogo from "../assets/images/backend/couchdb.svg";
import ElasticLogo from "../assets/images/backend/elasticsearch.svg";
import RedshiftLogo from "../assets/images/backend/aws-redshift.svg";
import GitLogo from "../assets/images/backend/git.svg";
import SendgridLogo from "../assets/images/backend/sendgrid.svg";
import TwilioLogo from "../assets/images/backend/twilio.svg";
import SentryLogo from "../assets/images/backend/sentry.svg";
import GunicornLogo from "../assets/images/backend/gunicorn.svg";
import GatsbyLogo from "../assets/images/backend/gatsby.svg";
import SonarqubeLogo from "../assets/images/backend/sonarqube.svg";
import QuasarLogo from "../assets/images/backend/quasar.png";
import ReactNativeLogo from "../assets/images/backend/react-native-firebase.svg";
import FlutterLogo from "../assets/images/backend/flutter.svg";
import IonicLogo from "../assets/images/backend/ionic.svg";
import AwsAthena from "../assets/images/backend/aws-athena.svg";
import Strapi from "../assets/images/backend/strapi-2.svg";
import Cta from "../components/cta";

const TechPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout seo={data.strapiHomepage.seo}>
      <div className="bg-white">
        <div className="text-center">
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Tech Stack
          </p>
        </div>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={PythonLogo} alt="Python" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={FlaskLogo} alt="Flask" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={DjangoLogo} alt="Django" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={NodejsLogo} alt="Node.js" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={ExpressLogo} alt="Express" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-3 lg:col-span-1">
              <img className="h-12" src={RubyLogo} alt="Ruby" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={Railslogo} alt="Rails" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={PhpLogo} alt="php" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={LaravelLogo} alt="Laravel" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-3 lg:col-span-1">
              <img className="h-12" src={LumenLogo} alt="Lumen" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-3 lg:col-span-1">
              <img className="h-12" src={RedisLogo} alt="Redis" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={HtmlLogo} alt="HTML" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={NginxLogo} alt="Nginx" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={VueLogo} alt="Vue.js" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-3 lg:col-span-1">
              <img className="h-12" src={ReactLogo} alt="React.js" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={AngularLogo} alt="Angular" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={CssLogo} alt="CSS3" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={JsLogo} alt="Javascript" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={JqueryLogo} alt="Jquery" />
            </div>
              <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
                <img className="h-12" src={QuasarLogo} alt="Quasar Framework" /></div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={VuetifyLogo} alt="Vuetify" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={TailwindLogo} alt="Tailwindcss" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={ReduxLogo} alt="Redux" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={BootstrapLogo} alt="Bootstrap" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={GmapsLogo} alt="Google Maps Api" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={SocketLogo} alt="Socket.io" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={MysqlLogo} alt="MySQL" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={PostgresLogo} alt="Postgres" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={OracleLogo} alt="Oracle" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={MongodbLogo} alt="MongoDB" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={CouchdbLogo} alt="CouchDB" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={ElasticLogo} alt="Elasticsearch" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={RedshiftLogo} alt="AWS Redshift" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={GitLogo} alt="Git" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={SendgridLogo} alt="Sendgrid" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={TwilioLogo} alt="Twilio" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={SentryLogo} alt="Sentry.io" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={GunicornLogo} alt="Gunicorn" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={GatsbyLogo} alt="Gatsby.js" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={SonarqubeLogo} alt="Sonarqube" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={ReactNativeLogo} alt="React Native" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={IonicLogo} alt="Ionic" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={FlutterLogo} alt="Flutter" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={AwsAthena} alt="AWS Athena" />
            </div>
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={Strapi} alt="Strapi" />
            </div>
          </div>
        </div>
        <div className="text-center">
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:tracking-tight">
            Cloud Platforms
          </p>
        </div>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
            <div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={AwsLogo} alt="AWS" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={DigitalLogo} alt="Digital Ocean" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={AzureLogo} alt="Azure" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={HerokuLogo} alt="Heroku" />
            </div><div className="col-span-1 flex justify-center p-2 border md:col-span-2 lg:col-span-1">
              <img className="h-12" src={FirebaseLogo} alt="Firebase" />
            </div>
          </div>
        </div>
      </div>
        <Cta />
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
  }
`;

export default TechPage;
